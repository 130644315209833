import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as goalsActions from './goals.actions';
import { catchError, delay, map, mergeMap, reduce, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Benchmark,
  ClassroomAnalytics,
  Facets,
  LevelLocation,
  Product,
  State,
  StudentAnalytics,
  WelGoal,
} from '@wam/shared';
import { GoalsService } from '../goals-data.service';
import { AssignmentsService } from '@app/shared/assignments.service';
import { forkJoin, of } from 'rxjs';
import { NotificationsService, NotificationType } from '@wam/notifications';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AssessmentsService } from '@app/shared/services/assessments.service';
import { select, Store } from '@ngrx/store';
import * as fromGoals from './goals.selectors';
import { isEmpty, some } from 'lodash-es';
import { GoalSaveConfirmationDialogComponent } from '@app/goals/goal-save-confirmation-dialog/goal-save-confirmation-dialog.component';
import { CourseAssignLicenseErrorDialogComponent } from '@app/shared/error-dialogs/course-assign-license-error-dialog/course-assign-license-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AssignmentResponse } from '@wap/catalog-v5';

@Injectable()
export class GoalsEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.load, goalsActions.updateSelectedFacets),
      mergeMap(() =>
        this.goalsService
          .getHits()
          .pipe(
            mergeMap((response: [ClassroomAnalytics[], number, Facets]) => [
              goalsActions.loadSuccess({ hits: response[0], totalHits: response[1] }),
              goalsActions.updateFacets({ facets: response[2] }),
            ]),
          ),
      ),
    ),
  );

  continueLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.continueLoad),
      mergeMap(() =>
        this.goalsService
          .getHits()
          .pipe(
            mergeMap((response: [ClassroomAnalytics[], number, Facets]) => [
              goalsActions.continueLoadSuccess({ hits: response[0], totalHits: response[1] }),
              goalsActions.updateFacets({ facets: response[2] }),
            ]),
          ),
      ),
    ),
  );

  loadClassAssignments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.loadClassCourses),
        mergeMap(({ classUuid }) =>
          this.analyticsService.getStudentsInClass(classUuid).pipe(
            mergeMap((students: StudentAnalytics[]) => {
              this.store.dispatch(
                goalsActions.updateClassHasStudents({ classUuid, hasStudents: !isEmpty(students) }),
              );
              return students.map((student) =>
                this.assignmentsService.getCourses(student.organization.uuid, student.uuid),
              );
            }),
            mergeMap((assignments) => assignments),
            reduce((acc, val) => acc.concat(val), []),
            tap((courses: AssignmentResponse[]) =>
              this.store.dispatch(
                goalsActions.loadClassAssignmentsSuccess({ courses: { [classUuid]: courses } }),
              ),
            ),
          ),
        ),
      ),
    {
      dispatch: false,
    },
  );

  loadStudentAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadStudentCourses),
      mergeMap(({ student }) =>
        this.assignmentsService.getCourses(student.organization.uuid, student.uuid).pipe(
          map((courses: AssignmentResponse[]) =>
            goalsActions.loadStudentCoursesSuccess({
              student,
              courses,
            }),
          ),
        ),
      ),
    ),
  );

  loadStudentAssignmentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.loadStudentCoursesSuccess),
        mergeMap(({ student, courses }) => {
          const erp = some(courses, (c) => c.document.product === Product.erp);
          const ems = some(courses, (c) => c.document.product === Product.ems);
          this.store.dispatch(
            goalsActions.loadStudentLocation({
              organization: student.organization.uuid,
              entity: student.uuid,
              products: { erp, ems },
            }),
          );
          return of(null);
        }),
      ),
    { dispatch: false },
  );

  studentAssignmentChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.studentCoursesChanged),
      mergeMap(({ student, courses }) =>
        this.goalsService.changeStudentAssignment(student, courses).pipe(
          mergeMap(() => [
            goalsActions.loadStudentCourses({ student }),
            goalsActions.studentCoursesChangedSuccess({ student, courses }),
          ]),
          catchError(({ failures }) => {
            const hasLicenseErrors = failures?.find((f) => f.isLicenseError);
            if (hasLicenseErrors) {
              this.showLicenseErrorDialog();
            } else {
              this.notificationsService.notify(
                NotificationType.ERROR,
                'Could not change student courses!',
              );
            }
            return of(
              goalsActions.loadStudentCourses({ student }),
              goalsActions.studentCoursesChangedFailed({ student, courses }),
            );
          }),
        ),
      ),
    ),
  );

  studentCoursesChangedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.studentCoursesChangedSuccess),
      delay(1000),
      map(() => goalsActions.load()),
    ),
  );

  classAssignmentChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.classCoursesChanged),
      mergeMap(({ classUuid, courses }) =>
        this.analyticsService.getStudentsInClass(classUuid).pipe(
          mergeMap((students: StudentAnalytics[]) =>
            this.goalsService.changeClassAssignment(students, courses).pipe(
              tap(({ failures, successes }) => {
                if (!isEmpty(failures) || !isEmpty(successes)) {
                  this.dialog.open(GoalSaveConfirmationDialogComponent, {
                    width: '400px',
                    position: {
                      top: '50px',
                    },
                    data: {
                      students,
                      failures,
                      successes,
                    },
                    panelClass: 'no-padding-dialog',
                  });
                }
              }),
              map(() => goalsActions.loadClassCourses({ classUuid })),
              catchError(() => {
                this.notificationsService.notify(
                  NotificationType.ERROR,
                  'Could not change class courses!',
                );
                return of(goalsActions.loadClassCourses({ classUuid }));
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateLevel = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.updateLevel),
      map(() => goalsActions.load()),
    ),
  );

  loadGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadGoals),
      mergeMap(({ organization, entity }) =>
        this.goalsService.getGoals(organization, entity).pipe(
          map((goals: WelGoal[]) => goalsActions.loadGoalsSuccess({ goals: { [entity]: goals } })),
          catchError(() => {
            this.notificationsService.notify(NotificationType.ERROR, 'Could not load goals data.');
            return of(goalsActions.loadGoalsSuccess({ goals: { [entity]: [] } }));
          }),
        ),
      ),
    ),
  );

  goalChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.goalChanged),
      mergeMap(({ organization, entity, goal }) =>
        this.goalsService.saveGoal(organization, entity, goal).pipe(
          map(() => goalsActions.loadGoals({ organization, entity })),
          catchError((err) => {
            this.notificationsService.notify(
              NotificationType.ERROR,
              'The goal data you entered was invalid.',
            );
            return of(goalsActions.loadGoals({ organization, entity }));
          }),
        ),
      ),
    ),
  );

  loadTargets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadTargets),
      mergeMap(() =>
        this.goalsService
          .getTargets()
          .pipe(
            map(([erp, ems, smartstart]) =>
              goalsActions.loadTargetsSuccess({ targets: { erp, ems, smartstart } }),
            ),
          ),
      ),
    ),
  );

  loadOrgLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadOrgLocations),
      mergeMap(() =>
        this.goalsService.getOrgLocations().pipe(
          map(([erp, ems]) => {
            const doNotChangeLevel = {
              id: -1,
              name: 'Do not change',
              locations: [{ id: -1, name: 'Do not change' }],
            };
            const doNotChangeLocation = { levelId: -1, locationId: -1 };
            erp.levels = [doNotChangeLevel, ...erp.levels];
            ems.levels = [doNotChangeLevel, ...ems.levels];
            erp.location = doNotChangeLocation;
            ems.location = doNotChangeLocation;
            return goalsActions.loadStudentLocationSuccess({
              entity: erp.organization,
              locations: { erp, ems },
            });
          }),
        ),
      ),
    ),
  );

  loadStudentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadStudentLocation),
      mergeMap(({ organization, entity, products }) =>
        this.goalsService.getStudentLocation(organization, entity, products).pipe(
          map(([erp, ems]) =>
            goalsActions.loadStudentLocationSuccess({
              entity,
              locations: { erp, ems },
            }),
          ),
        ),
      ),
    ),
  );

  loadLocationsForProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadLocationsForProduct),
      mergeMap(({ organization, entity, product }) => {
        const products = {
          erp: product === Product.erp,
          ems: product === Product.ems,
        };
        return this.goalsService.getStudentLocation(organization, entity, products).pipe(
          map(([erp, ems]) => {
            const locations = product === Product.erp ? { erp } : { ems };
            return goalsActions.loadLocationsForProductSuccess({
              entity,
              locations,
            });
          }),
        );
      }),
    ),
  );

  studentLocationChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.studentLocationChanged),
        mergeMap(({ organization, entity, location }) =>
          this.store.pipe(
            select(fromGoals.getLocation),
            take(1),
            map((locations) => [locations[entity], location, organization, entity]),
          ),
        ),
        mergeMap(
          ([currentLocation, location, organization, entity]: [
            LevelLocation,
            LevelLocation,
            string,
            string,
          ]) => {
            const product = location.product;
            if (
              currentLocation[product]?.location.locationId !== location.locationId ||
              currentLocation[product]?.location.levelId !== location.levelId
            ) {
              return this.goalsService.saveLocation(organization, entity, location, 'Student').pipe(
                tap(() =>
                  this.store.dispatch(
                    goalsActions.loadLocationsForProduct({ organization, entity, product }),
                  ),
                ),
                map(({ error }) => {
                  if (error) {
                    this.notificationsService.notify(NotificationType.ERROR, error);
                  }
                }),
              );
            }
            return of(null);
          },
        ),
      ),
    { dispatch: false },
  );

  classLocationChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.classLocationChanged),
        mergeMap(({ organization, entity, location }) =>
          this.analyticsService.getStudentsInClass(entity).pipe(
            mergeMap((students: StudentAnalytics[]) =>
              forkJoin(
                students.map((student) =>
                  this.goalsService.saveLocation(
                    organization,
                    student.uuid,
                    location,
                    `${student.givenName} ${student.familyName}`,
                  ),
                ),
              ).pipe(
                mergeMap((results) => {
                  results
                    .filter(({ error }) => error)
                    .forEach(({ error }) =>
                      this.notificationsService.notify(NotificationType.ERROR, error, 10000),
                    );
                  return of(null);
                }),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  loadBenchmarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadBenchmarks, goalsActions.loadBenchmarksFromStudentDialog),
      mergeMap(({ type, organization, entity }) =>
        this.assessmentsService.getBenchmarks(organization, entity).pipe(
          map((benchmarks: Benchmark[]) =>
            goalsActions.loadBenchmarksSuccess({
              benchmarks: { [entity]: benchmarks },
            }),
          ),
          catchError(() => {
            if (type !== goalsActions.loadBenchmarksFromStudentDialog.type) {
              this.notificationsService.notify(
                NotificationType.WARNING,
                'Benchmarks are not yet available.',
              );
            }
            return of(goalsActions.loadBenchmarksSuccess({ benchmarks: { [entity]: [] } }));
          }),
        ),
      ),
    ),
  );

  benchmarkChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.benchmarkChanged),
      mergeMap(({ organization, entity, benchmark }) => {
        return this.assessmentsService.saveBenchmark(organization, entity, benchmark).pipe(
          map(() => goalsActions.loadBenchmarks({ organization, entity })),
          catchError(() => of(goalsActions.loadBenchmarks({ organization, entity }))),
        );
      }),
    ),
  );

  loadGradeGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadGradeGoals),
      mergeMap(({ organization, entity, grade, product }) =>
        this.goalsService.getGradeGoals(organization, entity, grade, product).pipe(
          map((goals) => goalsActions.loadGoalsSuccess({ goals })),
          catchError((error) => {
            this.notificationsService.notify(NotificationType.ERROR, 'Could not load goals data.');
            return of(goalsActions.loadGradeGoalsFailed({ error }));
          }),
        ),
      ),
    ),
  );

  saveGradeGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.saveGradeGoal),
      mergeMap(({ goal }) =>
        this.goalsService.saveGradeGoal(goal).pipe(
          map((response) => goalsActions.saveGradeGoalSuccess({ goal: response })),
          catchError((error) => of(goalsActions.saveGradeGoalFailed({ error }))),
        ),
      ),
    ),
  );

  saveGradeGoalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.saveGradeGoalSuccess),
        tap(() =>
          this.notificationsService.notify(NotificationType.SUCCESS, 'Goal updated successfully.'),
        ),
      ),
    { dispatch: false },
  );

  saveGradeGoalFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.saveGradeGoalFailed),
        tap(() =>
          this.notificationsService.notify(
            NotificationType.ERROR,
            'An error occurred while trying to update goal.',
          ),
        ),
      ),
    { dispatch: false },
  );

  loadLock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.loadLock),
      mergeMap(({ organization, entity }) =>
        this.goalsService.getLock(organization, entity).pipe(
          map((lock: { [key: string]: boolean }) => goalsActions.loadLockSuccess({ lock })),
          catchError((error) => of(goalsActions.loadLockFailed({ error }))),
        ),
      ),
    ),
  );

  updateLock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalsActions.updateLock),
      mergeMap(({ organization, entity, locked }) =>
        this.goalsService.updateLock(organization, entity, locked).pipe(
          map(() => goalsActions.updateLockSuccess()),
          catchError(() => of(goalsActions.updateLockFailed({ organization, entity, locked }))),
        ),
      ),
    ),
  );

  updateLockFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goalsActions.updateLockFailed),
        tap(() =>
          this.notificationsService.notify(
            NotificationType.ERROR,
            'An error occurred while trying to update goal lock.',
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private goalsService: GoalsService,
    private assignmentsService: AssignmentsService,
    private assessmentsService: AssessmentsService,
    private notificationsService: NotificationsService,
    private analyticsService: AnalyticsService,
    private store: Store<State>,
    private dialog: MatDialog,
  ) {}

  private showLicenseErrorDialog(): void {
    this.dialog.open(CourseAssignLicenseErrorDialogComponent, {
      width: '400px',
      panelClass: 'no-padding-dialog',
    });
  }
}
